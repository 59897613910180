@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-default;
  }

  input,
  button {
    @apply outline-none;
  }

  input::placeholder,
  input::-webkit-input-placeholder {
    font-size: 0.875rem;
    color: #bdbdbd;
  }

  /* 웹킷(WebKit) 기반 브라우저용 */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* 파이어폭스(Firefox)용 */
  .hide-scrollbar {
    scrollbar-width: none;
  }

  .slick-dots li {
    margin: 0;
    width: 12px;
    height: 12px;
  }

  .slick-dots li button {
    width: 6px;
    height: 6px;
  }

  .slick-dots li button:before {
    color: #e9e8e8;
    opacity: 1;
    width: 6px;
    height: 6px;
  }

  .slick-dots li.slick-active button:before {
    color: #ffb347;
    opacity: 1;
  }
}

@font-face {
  font-family: 'OwnglyphMinhyeChae';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/Minhye.woff')
    format('woff');
}

@font-face {
  font-family: 'Gyobomungo2019';
  src: url('assets/fonts/KyoboHandwriting2019.otf') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
}

@font-face {
  font-family: 'HelveticaNowDisplay';
  src: url('assets/fonts/HelveticaNowDisplay.woff') format('woff');
}
